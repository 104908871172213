<template>
    <div class="timer-box" :class="[isAvailable ? 'available' : 'not-available', [isMoreThanSixHours ? 'maximum-six-hours-block' : 'maximum-six-hours-hide'], [isMoreThanSixHours ? '' : 'max-hours']]">
        <span class="corner-dot" :style="{ backgroundColor: colorDot ? colorDot.color : '#374673' }"></span>
        <div class="icon-container">
            <img :src="timer.icon" alt="Timer icon" class="icon" />
        </div>
        <div class="text-container">
            <h2 class="timer-title">{{ timer.name }}</h2>
            <p class="timer-text timer-description">{{ timer.description }}</p>
            <p v-if="isAvailable && currentPendingAlarm" class="time">
                <span v-if="timer.type == 2" class="timer-text">{{ $t('timers.pending_signing') }}:</span>
                <span v-else class="timer-text">{{ $t('timers.pending') }}:</span>
                <span class="timer-text"> {{ timer.nextAlarmText ? timer.nextAlarmText : currentPendingAlarm }}h</span>
            </p>
            <p v-else class="time">
                <span class="timer-text">{{ $t('timers.next_alarm') }}:</span>
                <span v-show="nextAlarm" class="timer-text"> {{ timer.nextAlarmText ? timer.nextAlarmText : nextAlarm }}h</span>
            </p>
        </div>
        <template v-if="isAvailable">
            <div class="timer-countdown-container action" :class="['type' + timer.type]" @click="handleTimerClick"></div>
        </template>
        <template v-else>
            <div class="timer-countdown-container hour">
                <div class="graph chart">
                    <div class="c100" :class="['p' + pending_pc, { small: isSmallScreen }]">
                        <div class="slice">
                            <div class="bar" :style="{ borderColor: timer.color + ' !important' }"></div>
                            <div class="fill" :style="{ borderColor: timer.color + ' !important' }"></div>
                        </div>
                        <CountDown :nextAlarm="timer.nextAlarm" @timeStatusChanged="handleTimeStatusChange" />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import CountDown from './CountDown.vue'

export default {
    name: 'SingleTimer',
    components: { CountDown },
    props: { timerId: String, colorDot: Object },
    data() {
        return {
            clickTimestamp: null,
            windowWidth: window.innerWidth,
            isMoreThanSixHours: true
        }
    },
    computed: {
        isSmallScreen() {
            return this.windowWidth < 1025
        },
        timer() {
            if (this.timerId) {
                return this.$store.getters['timer/getTimerById'](this.timerId) || null
            }
            return null
        },
        pending_pc() {
            const self = this

            if (!self.timer || !self.timer.nextAlarm) {
                return 0 // Return 0 if timer or nextAlarm is not available
            }

            const nextAlarm = moment(self.timer.nextAlarm)

            let pastAlarm

            if (self.timer.previousAlarm) {
                pastAlarm = moment(self.timer.previousAlarm)
            } else if (self.timer.times && self.timer.times.length > 0) {
                const todayLastAlarm = moment(self.timer.times.at(-1).timestamp)

                const todayLastAlarmClone = todayLastAlarm.clone()
                const yesterdayLastAlarm = todayLastAlarmClone.subtract(1, 'days')

                const yesterdayLastAlarmTimestamp = yesterdayLastAlarm.unix()
                pastAlarm = moment(yesterdayLastAlarmTimestamp)
            } else {
                return 0 // Return 0 if we can't determine pastAlarm
            }

            const currentTimestampWithSeconds = moment().valueOf()
            const totalPendingTimeInSeconds = nextAlarm.diff(pastAlarm, 'seconds')
            const currentPendingTimeInSeconds = moment(currentTimestampWithSeconds).diff(pastAlarm, 'seconds')

            const elapsed = (currentPendingTimeInSeconds / totalPendingTimeInSeconds) * 100

            return parseInt(elapsed)
        },
        countDownTargetTime() {
            const self = this
            const time = self.nextAlarm
            const timeParts = time.split(':')

            return {
                targetHour: timeParts[0],
                targetMinute: timeParts[1]
            }
        },
        isAvailable() {
            if (this.timer) {
                return this.timer.isAvailable
            }
        },
        nextAlarm() {
            if (this.timer) {
                const nextAlarm = this.timer.nextAlarm

                if (nextAlarm) {
                    return moment(nextAlarm).format('HH:mm')
                } else return null
            }
        },
        currentPendingAlarm() {
            if (this.timer) {
                const currentPendingAlarm = this.timer.currentPendingAlarm

                if (currentPendingAlarm) {
                    return moment(currentPendingAlarm).format('HH:mm')
                } else return null
            }
        },
        userAppAccess() {
            return this.$store.getters['loginUser/getHasAccessApp']
        },
        loggedUser() {
            return this.userAccess ? this.userAccess : this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            if (this.loggedUser) {
                return this.$store.getters['employee/getEmployee'](this.loggedUser)
            }
            return false
        }
    },
    methods: {
        handleTimeStatusChange(isMoreThanSixHours) {
            this.isMoreThanSixHours = !isMoreThanSixHours
        },
        setAlarmAsDone() {
            const self = this
            self.$store.dispatch('timer/setAlarmAsDone', { alarm: self.timer, checkDateTimestamp: self.clickTimestamp })
            this.$emit('updateTimer', true)
        },
        handleTimerClick() {
            const self = this
            const timestamp = Date.now()
            self.clickTimestamp = timestamp

            if (self.timer.type == 2) {
                const kitchenMode = localStorage.getItem('kitchenMode') ? (localStorage.getItem('kitchenMode') === 'true' ? true : false) : false
                const userLogged = this.$store.getters['loginUser/getLocalEmployee']

                if (kitchenMode && !userLogged) {
                    this.$popup.confirm({
                        message: this.$t('timers.popup_sign.title', { timer: this.timer.name }),
                        textSave: this.$t('timers.popup_sign.confirm'),
                        textCancel: this.$t('timers.popup_sign.cancel'),
                        autoClose: false,
                        callSave: async function (self2, store) {
                            self.$store.commit('loginUser/setRefreshLayout', false)
                            await self.$popup.close()
                            self.$popup.login({
                                list: store.getters['employee/getLoginUsers'](0),
                                callAfterAccess: function () {
                                    self.setAlarmAsDone()
                                    self.$popup.close()
                                    self.$store.commit('loginUser/setRefreshLayout', true)
                                }
                            })
                        }
                    })
                } else {
                    const self = this
                    if (!self.userAppAccess) {
                        this.$popup.alert({ message: this.$t('answer.no_access_app_sign', { name: self.user.name }) })
                    } else {
                        this.$popup.confirm({
                            message: this.$t('timers.popup_sign.title', { timer: this.timer.name }),
                            textSave: this.$t('timers.popup_sign.confirm'),
                            textCancel: this.$t('timers.popup_sign.cancel'),
                            callSave: this.setAlarmAsDone
                        })
                    }
                }
            } else {
                this.setAlarmAsDone()
            }

            this.$emit('updateTimer', true)
        },
        updateWindowWidth() {
            this.windowWidth = window.innerWidth
        }
    },
    watch: {},
    mounted() {
        window.addEventListener('resize', this.updateWindowWidth)
    },
    beforeDestroy() {
        this.clickTimestamp = null
        window.removeEventListener('resize', this.updateWindowWidth)
    }
}
</script>

<style lang="scss" scoped>
.maximum-six-hours-hide {
    display: none !important;
}

.maximum-six-hours {
    display: block !important;
}

.timer-box {
    border-radius: 4px;
    box-sizing: border-box;
    padding: 8px;
    display: grid;
    grid-template-columns: 40px 1fr 120px;
    min-height: 96px;
    max-height: fit-content;

    h2 {
        margin-block-start: 0;
        margin-block-end: 0;
        padding: 0;
    }

    p {
        padding: 0;
    }

    .icon-container {
        height: 100%;
        // height: 55px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 16px;

        .icon {
            width: 24px;
            height: 24px;
            object-fit: contain;
            margin-top: 32px;
        }

        @media (max-width: 768px) {
            .icon {
                width: 24px;
                height: 24px;
                object-fit: contain;
                margin-top: 16px;
                margin-left: -1rem;
            }
        }
    }

    .text-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 4px;
        padding-right: 4px;
        overflow: hidden;

        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
    }

    .timer-title {
        font-size: 18px;
        // height: 36px;
        padding-top: 4px;

        width: 100%;
        max-width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .timer-text {
        font-family: 'Avenir-Regular';
        font-size: 16px;
        // line-height: normal;
        line-height: 130%;
        font-weight: 400;
    }

    .timer-description {
        padding-top: 4px;
        width: 100%;

        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .time {
        padding-top: 0.2em;
        padding-bottom: 0.2em;

        .timer-text {
            font-weight: 500;
        }
    }

    .timer-countdown-container {
        display: grid;
        place-items: center;
        &.hour {
            background-size: 90px;
        }
        &.action {
            cursor: pointer;
            background-repeat: no-repeat;
            background-size: 70px;
            background-position: center;

            &.type1 {
                background-image: img('circle_play.svg');
            }
            &.type2 {
                background-image: img('circle_check.svg');
            }
        }
    }

    .graph.chart {
        height: 90px;
        width: 90px;
        padding: 0;
        // margin: 0px !important;

        .c100 {
            position: relative;
            // width: 3.3625rem;
            // height: 3.3625rem;
            width: 100%;
            height: 100%;
            // margin: 0px !important;

            .bar,
            .fill,
            &::after {
                // width: calc(3.3625rem - 1.125rem);
                // height: calc(3.3625rem - 1.125rem);
                width: calc(90px - calc(70px * 0.21));
                height: calc(90px - calc(70px * 0.21));
            }
        }
    }

    @media (min-width: 901px) and (max-width: 1024px) {
        .graph.chart {
            .c100 {
                .bar,
                .fill,
                &::after {
                    width: calc(90px - calc(70px * 0.18));
                    height: calc(90px - calc(70px * 0.18));
                }
            }
        }
    }

    @media (min-width: 1025px) {
        // height: 160px;
        min-height: 160px;
        max-height: max-content;
        grid-template-columns: 60px 1fr 120px;
        // padding-top: 16px;

        .text-container {
            width: 100%;
            height: 100%;
            overflow: hidden;

            .timer-title {
                line-height: normal;
                font-size: 26px;
                // height: 54px;
            }

            .timer-text {
                line-height: 150%;
                font-size: 20px;
            }
        }

        // .timer-title {
        //     height: 55px;
        // }

        .icon-container,
        .timer-title {
            padding-top: 14px;
        }

        .icon {
            width: 44px;
            min-width: 44px;
            height: 44px;
            min-height: 44px;
        }

        .timer-countdown-container.action {
            background-size: 100px;
        }

        .graph.chart {
            width: 120px;
            height: 120px;

            .c100 {
                // width: 100px;
                // height: 100px;
                width: 120px;
                height: 120px;

                // span {
                //     font-size: 24px;
                //     line-height: 5.5rem;
                // }

                // .bar,
                // .fill,
                // &::after {
                //     width: calc(5.3625rem - 1.125rem);
                //     height: calc(5.3625rem - 1.125rem);
                // }

                .bar,
                .fill,
                &::after {
                    width: calc(120px - calc(100px * 0.18));
                    height: calc(120px - calc(100px * 0.18));

                    // width: calc(5.3625rem - 1.125rem);
                    // height: calc(5.3625rem - 1.125rem);
                }
            }
        }
    }
}

.available {
    background-color: #bd1f1e;

    .timer-title {
        color: $color-white;
    }

    .timer-text {
        color: $color-white;
    }

    .icon {
        filter: invert(100%) sepia(0%) saturate(7486%) hue-rotate(204deg) brightness(112%) contrast(100%);
    }
}

.not-available {
    background-color: $color-white;

    .timer-text {
        color: #666666;
    }
}

.corner-dot {
    position: absolute;
    width: 16px; /* Tamaño del círculo */
    height: 16px; /* Tamaño del círculo */
    background-color: #ff0000; /* Color del círculo */
    border-radius: 50%; /* Hace que el punto sea redondo */
    margin-right: 1.125rem;
}
</style>
